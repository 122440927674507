<script>
import useJwt from '@/auth/useJwt'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'AffiliateMarketingIndex',
  components: { quillEditor },
  data() {
    return {
      snowOption: {

      },
      affiliatePayload: {
        isLoading: false,
        data: {
          status: 2,
          description: '',
          commission_value: 0,
        },
      },
      isLoading: false,
      customers: null,
    }
  },
  created() {
    this.getAffiliateSettings()
  },
  methods: {
    getAffiliateSettings() {
      useJwt.get('/affiliate/settings')
        .then(({ data }) => {
          this.affiliatePayload.data = data.affiliateSettings
        }).catch(err => {
          console.error(err)
        }).finally()
    },
    updateAffiliateSettings() {
      this.affiliatePayload.isLoading = true
      useJwt.post('/affiliate/settings', this.affiliatePayload.data)
        .then(({ data }) => {
          this.affiliatePayload.data = data.affiliateSettings
          this.$root.showToast('success', 'Success', data.message)
        }).catch(err => {
          console.error(err)
        }).finally(() => {
          this.affiliatePayload.isLoading = false
        })
    },
  },
}
</script>

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <h3>
              {{ $t('Affiliate Marketing') }}
            </h3>
            <div class="mt-1">
              {{ $t('Affiliate Marketing Description') }}
            </div>
            <b-form-group class="mt-2" :label="$t('Is Active ?')" label-for="v-is-featured">
              <b-form-checkbox
                id="v-is-featured"
                v-model="affiliatePayload.data.status"
                :unchecked-value="2"
                :value="1"
                class="custom-control-primary"
                name="is_active"
                switch
              >
                <span class="switch-icon-left"><feather-icon icon="CheckIcon" /></span>
                <span class="switch-icon-right"><feather-icon icon="XIcon" /></span>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group :label="$t('Commission')" label-for="v-sort">
              <b-form-input id="v-commotion" v-model="affiliatePayload.data.commission_value" :placeholder="$t('Commission')" required/>
            </b-form-group>

            <b-form-group :label="$t('Description')" label-for="description">
              <quill-editor v-model="affiliatePayload.data.description" :options="snowOption"/>
            </b-form-group>

            <b-button :disabled="affiliatePayload.isLoading" size="sm" variant="primary" class="mb-1" @click="updateAffiliateSettings">
              <feather-icon icon="PlusSquareIcon" />
              <template>
                <span v-if="!affiliatePayload.isLoading">
                  {{ $t('Save') }}
                </span>
                <b-spinner v-else small/>
              </template>
            </b-button>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped lang="scss">

</style>
